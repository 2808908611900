'use client';
import { createContext, ReactNode, useState } from 'react';

interface AuthFormContextType {
  error: string;
  setError: (error: string) => void;
}

export const AuthFormContext = createContext<AuthFormContextType>({
  error: '',
  setError: () => {},
});

const AuthFormProvider = ({ children }: { children: ReactNode }) => {
  const [error, setError] = useState<string>('');

  return (
    <AuthFormContext.Provider value={{ error, setError }}>
      {children}
    </AuthFormContext.Provider>
  );
};

export default AuthFormProvider;
